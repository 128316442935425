<template>
  <div>
    <label :for="`${v_model}_selected`">{{ title }}</label>
    <Multiselect @input="$parent[on_change]" required track-by="id" :multiple="false"
                 :id="`${v_model}_selected`" v-model="$parent.form[v_model]" :label="label"
                 :placeholder="`Type Or Search ${title}`" :options="lists" @search-change="getLists"></Multiselect>
  </div>
</template>

<script>
import ApiService  from "@/services/api.service";
import Multiselect from 'vue-multiselect';

export default {
  name      : "DropDownPatientDoctorComponent",
  components: {Multiselect},
  props     : ['title', 'route', 'v_model', 'label', 'on_change'],
  data      : () => ({
    lists: [],
  }),
  mounted() {
    this.getLists()
  },
  methods: {
    getLists: function (search) {
      let params = {
        search: search
      };
      ApiService.get(`/dropdown/${this.route}`, {params: params}).then((res) => {
        this.lists = res.data.data
      }).catch(error => {
        NotificationService.error(error.response.data.message);
      })
    }
  }
}
</script>
